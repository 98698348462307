import { widgetize, WidgetPropsMapping } from '@sg-widgets/react-core';
import { DsDropZone } from '@sgdocs/design-system';

widgetize('sgm-docs-drop-zone', DsDropZone, {
  type: WidgetPropsMapping.asString({
    description: 'The type of the component, could be large, medium, compact or small',
  }),
  onSelectedFiles: WidgetPropsMapping.asEventEmitter('selected-files', {
    description:
      "Event sent when the user has selected files, it contains an array of <a class='btn btn-link text-info px-0 mb-0' href='https://developer.mozilla.org/en-US/docs/Web/API/File'>File</a> object",
  }),
  addLabel: WidgetPropsMapping.asString({
    description: 'Translated label "Add attachment"',
  }),

  dragLabel: WidgetPropsMapping.asString({
    description: 'Translated label "Drag or"',
  }),

  selectLabel: WidgetPropsMapping.asString({
    description: 'Translated label "select"',
  }),

  fileLabel: WidgetPropsMapping.asString({
    description: 'Translated label "your file to this area to upload"',
  }),

  browseLabel: WidgetPropsMapping.asString({
    description: 'Translated label "Browse file"',
  }),
});
